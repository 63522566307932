import React from "react";
import { render } from "react-dom";
import { Parallax } from "react-parallax";

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};
const insideStyles = {
  background: "white",
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)"
};

import image1 from "./imgs/1.jpg";
import image2 from "./imgs/2.jpg";
import image3 from "./imgs/3.jpg";
import image4 from "./imgs/4.jpg";

class MainParallax extends React.Component {
  render() {
    return (
      <div style={styles}>
        <Parallax bgImage={image1} strength={500}>
          <div style={{ height: 500 }}>
            <div style={insideStyles}>Hello There!</div>
          </div>
        </Parallax>

        <Parallax bgImage={image3} strength={500}>
          <div style={{ height: 500 }}>
            <div style={insideStyles}>Take a look at these</div>
          </div>
        </Parallax>

        <Parallax bgImage={image2} strength={500}>
          <div style={{ height: 500 }}>
            <div style={insideStyles}>While I build an actual site</div>
          </div>
        </Parallax>

        <Parallax
          bgImage={image4}
          strength={200}
          renderLayer={percentage => (
            <div>
              <div
                style={{
                  position: "absolute",
                  background: `rgba(255, 125, 0, ${percentage * 1})`,
                  left: "50%",
                  top: "50%",
                  borderRadius: "50%",
                  transform: "translate(-50%,-50%)",
                  width: percentage * 500,
                  height: percentage * 500
                }}
              />
            </div>
          )}
        >
          <div style={{ height: 500 }}>
            <div style={insideStyles}>Thanks</div>
          </div>
        </Parallax>
      </div>
    );
  }
}

render(<MainParallax />, document.getElementById("app"));
